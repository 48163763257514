import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';
import { Image } from 'nordic/image';

const namespace = 'ui-homes-icon ui-homes-icon--meliplus';

const IconMeliPlus = ({ className = null }) => (
  <Image
    className={classnames(namespace, className)}
    src="icon-meli-plus.svg"
    alt="Icono de Meli +"
  />
);

IconMeliPlus.propTypes = {
  className: string,
};

const Icon = React.memo(IconMeliPlus);
Icon.ICON_ID = 'meliplus_icon';

export default Icon;

