import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@andes/card';

import MeliPlus from '../../../commons/icons/meliplus';
import StyledLabelFormated from '../../../commons/styled-label/styled-label-formated';
import IconTruck from '../../../commons/icons/truck';
import IconFactoryModule from '../../../commons/icons/factory-builder';

const IconFactory = IconFactoryModule([
  MeliPlus,
  IconTruck,
]);

const NewsRow = (props) => (
  <section className="news-row">
    <Card className="container">
      <StyledLabelFormated
        dataModel={props}
        as="p"
        iconFactory={IconFactory}
      />
    </Card>
  </section>
);

NewsRow.propTypes = { dataModel: PropTypes.shape({}) };

export default NewsRow;
